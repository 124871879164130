import * as React from "react"
import "../styles/index.css"
import Logo from "../images/LogoNoTextSVG.inline.svg"


const OpenSign = (
  <div id="openSign" class="currentlyOpen">
    We're currently Open
  </div>
)

const ClosedSign = (
  <div id="closedSign" class="currentlyClosed">
  We're currently Closed
  </div>
)

function isOpen() {
  const myDay = new Date().getDay();
  const myTime = new Date().toLocaleString("en-GB", { timeZone: "Europe/London" }).split(",")[1].trim();
  const myHour = myTime.split(":")[0];
  const myMinute = myTime.split(":")[1];

  switch (myDay) {
      case 0: 
      case 1:
      case 2:
      case 3:
      case 4:
        if (myHour < 16 || myHour > 22) {
          return ClosedSign;
        }
        break;
      case 5:
      case 6:
        if (myHour < 16 || (myHour > 22 && myMinute > 30)) {
          return ClosedSign;
        }
        break;
      default:
        return OpenSign;
  }

  return OpenSign;
}

const openInNewTab = url => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

const IndexPage = () => {
  return (
    <main id="main">
      <div id="svgContainer">
        <div id="svgHeader">
          <Logo />   
        </div>
        <h1>The Auld Hoose</h1>
      </div>
      <h2 id="header">
        Website In Development
      </h2>
      <div id="contactmenu"> 
        <button id="menuButton" onClick={() => openInNewTab('Menu.pdf')}>Menu</button>
        <h2>Contact Us <a href="tel:01316533687">0131 653 3687</a></h2>
      </div>
      <div class="restuarantStatus"> 
      { isOpen() }
      </div>
    </main>
  )
}

export default IndexPage

export const Head = () => <title>Auld Hoose Chinese</title>